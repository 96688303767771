import React, { useEffect } from 'react';
import CheckMark from '../../assets/image/checkmark.svg';
import { useSelector } from 'react-redux';
import { initialStateProps } from '../../helpers/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LocalizedString } from '../../shared/localization';

const Success = () => {
  
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId') || '';

  const { resValidateFindID, navigateToSucces } = useSelector((state: initialStateProps) => state);
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href =`/hyundai-one-id?clientId=${clientId}`;
  };
  useEffect(() => {
    if(!navigateToSucces) {
      navigate(`/findid?clientId=${clientId}`);
    }
  }, [navigateToSucces, navigate, clientId])
  return (
    <div className="flex flex-col justify-center items-center  h-screen bg-background p-4 ">
      <div className="flex flex-col justify-center items-center">
        <img src={CheckMark} alt="CheckMark" className="w-[80px] h-[80px] mb-7" />
        <div className="flex flex-col items-center">
          <h1 className="text-2xl text-black hyundaiTextHead pb-2">{LocalizedString.idMigration.labelSuccess}</h1>
          <p className="text-base text-black pb-1">{LocalizedString.idMigration.descPleaseFindYourID}</p>
          {resValidateFindID &&
            resValidateFindID.map((item, index: number) => {
              return (<><h1 className="text-lg font-bold text-black py-1" key={index}>{item.email}</h1><p className="text-base text-black pt-1">Migration Status : {item.migrationStatus}</p><p className="text-base text-black pt-1">Platform : {item.application}</p></>);
            })}
        </div>
        <button onClick={handleLogin} className="flex flex-row items-center justify-center py-[14px] px-[24px] mt-6 bg-primary text-base text-white hyundaiTextHead self-stretch">
          {LocalizedString.idMigration.btnGotoLogin}
        </button>
      </div>
    </div>
  );
};

export default Success;
