import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/icon';
import Loading from '../../components/loading';
import ModalInfo from '../../components/modal/ModalInfo/ModalInfo';
import { alertMessage } from '../../helpers/helper';
import { initialStateProps } from '../../helpers/types';
import {
  onGetUserList,
  setAccountList,
  setAddtionalAccountBlueLink,
  setAddtionalAccountCtb,
  setAddtionalAccountMyhyundai,
  setIsAbleToSearch,
  setSelcetAdditional,
  submitSearchAdditional,
} from '../../ssoSlicer/ssoSlice';
import { LocalizedString } from '../../shared/localization';

interface AdditionalAccountProps {
  onBack: () => void;
  onBackSucces: () => void;
}
const AdditionalAccount: FC<AdditionalAccountProps> = ({ onBack, onBackSucces }) => {
  const dispatch = useDispatch();
  const { ctb, bluelink, myhyundai } = useSelector((state: initialStateProps) => state.additionalAccount);
  const { selectAdditional } = useSelector((state: initialStateProps) => state);
  const [deleteBy, setDeleteBy] = useState<{ key: string; by: string }>({ key: '', by: '' });
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const { loading } = useSelector((state: initialStateProps) => state);
  const [modalInfo, setModalInfo] = useState<{ title: string; desc: string; cancel: string; confirm: string }>({
    title: LocalizedString.idMigration.titleDeleteEmailID,
    desc: LocalizedString.idMigration.descDeleteEmailID,
    cancel: LocalizedString.idMigration.btnNo,
    confirm: LocalizedString.idMigration.btnYesDelete,
  });

  const Header = () => (
    <header className="p-2 flex justify-between items-center bg-white sticky top-0 z-10">
      <button style={{ paddingRight: 16 }} onClick={onCancelAddtional}>
        <Icon icon="arrow-left" size={20} />
      </button>
      <strong className="font-1 mx-auto">{LocalizedString.idMigration.labelAddAdditionalAccount}</strong>
    </header>
  );

  const onConfirmSaveHandler = () => {
    setModalInfo({
      title: LocalizedString.idMigration.titleAddAccountConfirmation,
      desc: LocalizedString.idMigration.descAddAccountConfirmation,
      cancel: LocalizedString.idMigration.btnNo,
      confirm: LocalizedString.idMigration.btnYesAddAccount,
    });
    setModalDelete(true);
  };

  const onSaveHandler = () => {
    dispatch(submitSearchAdditional(selectAdditional))
      .unwrap()
      .then((res: any) => {
        if (res) {
          const email = localStorage.getItem('currentEmail');
          const phone = localStorage.getItem('currentPhoneNumber');
          GetUserLists(phone, email);
          dispatch(setIsAbleToSearch(false));
        } else {
          alertMessage.error(LocalizedString.idMigration.errFailedToAddYourAccount);
        }
      })
      .catch((err: any) => alertMessage.error(LocalizedString.idMigration.errFailedToAddYourAccount));
  };

  const GetUserLists = (phone: any, email: any) => {
    const payload = {
      email: email,
      phoneNumber: phone,
      source: 'CtbMyIDM',
    };
    dispatch(onGetUserList(payload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          localStorage.setItem('idMigration', res.idMigration);
          const { bluelink, ctb, myhyundai } = res;
          const data = [
            {
              platform: 'Bluelink',
              account: bluelink,
            },
            {
              platform: 'myHyundai',
              account: myhyundai,
            },
            {
              platform: 'Clicktobuy',
              account: ctb,
            },
          ];

          if (bluelink.length) {
            let idButtonDisabled = '0';
            let inMyHyundai = false;
            let inCtb = false;
            for (let i = 0; i < bluelink.length; i++) {
              const val = bluelink[i];
              if (myhyundai.length) {
                const myhData = myhyundai.filter((v: any) => v.email === val.email);
                if (myhData.length > -1) {
                  inMyHyundai = true
                } else {
                  inMyHyundai = false
                }
              }
              if (ctb.length) {
                const ctbData = ctb.filter((v: any) => v.email === val.email);
                if (ctbData.length > -1) {
                  inCtb = true
                } else {
                  inCtb = false
                }
              }
            }
            idButtonDisabled = (inMyHyundai && inCtb ? '1' : '0')
            localStorage.setItem('disableSearch', idButtonDisabled);
          } else {
            localStorage.setItem('disableSearch', '0');
          }
          dispatch(setAccountList(data));
          onBackSucces();
        }
      })
      .catch((err: any) => console.log(err, 'ini rerorr'));
  };

  const onShowModalDelete = (key: string, by: string) => {
    setModalInfo({
      title: LocalizedString.idMigration.titleDeleteEmailID,
      desc: LocalizedString.idMigration.descDeleteEmailID,
      cancel: LocalizedString.idMigration.btnNo,
      confirm: LocalizedString.idMigration.btnYesDelete,
    });
    setDeleteBy({ key, by });
    setModalDelete(true);
  };

  const onActionModal = () => {
    if (modalInfo.title === 'Cancel Adding Account') {
      onBackSucces();
      return;
    }
    if (modalInfo.title === 'Add Account Confirmation') {

      onSaveHandler();
      return;
    }
    if (deleteBy.by === 'hyundai') {
      const hyundaiTemp = myhyundai.filter((item) => item.Key !== deleteBy.key);
      dispatch(setAddtionalAccountMyhyundai(hyundaiTemp));
    }
    if (deleteBy.by === 'ctb') {
      const ctbTemp = ctb.filter((item) => item.Key === deleteBy.key);
      dispatch(setAddtionalAccountCtb(ctbTemp));
    }
    if (deleteBy.by === 'bluelink') {
      const blueLinkTemp = bluelink.filter((item) => item.Key !== deleteBy.key);
      dispatch(setAddtionalAccountBlueLink(blueLinkTemp));
    }
    const tempSelect = selectAdditional?.filter((item) => item.Key !== deleteBy.key);
    dispatch(setSelcetAdditional(tempSelect));
    setModalDelete(false);
  };

  const onCancelAddtional = () => {
    setModalInfo({
      title: LocalizedString.idMigration.titleCancelAddingAccount,
      desc: LocalizedString.idMigration.descCancelAddingAccount,
      cancel: LocalizedString.idMigration.btnNo,
      confirm: LocalizedString.idMigration.btnYesCancel,
    });
    setModalDelete(true);
  };

  return (
    <>
      <div className="fixed w-full h-full z-10 top-0 left-0 bg-background overflow-auto">
        <Header />
        <div className="w-full md:w-[480px] mx-auto">
          <div className="flex flex-col items-start py-8 px-6">
            <div className="flex flex-col items-start md:w-[480px]">
              <h1>{LocalizedString.idMigration.labelVerificationSucceded}</h1>
              <p>{LocalizedString.idMigration.labelPLeaseChooseAccountsToIntegrate}</p>
            </div>
            {myhyundai && myhyundai?.length > 0 && (
              <div className="flex flex-col items-start py-4 my-4 border-2 border-border bg-white w-full md:w-[480px] lg:h-auto">
                <div className="flex flex-col items-start px-4">
                  <p className="text-xs text-neutral">{LocalizedString.idMigration.labelPlatform}</p>
                  <h1 className="text-lg text-primary ">{LocalizedString.idMigration.labelMyHyundai}</h1>
                </div>
                <div className="flex flex-col items-start self-stretch ">
                  {myhyundai.map((res, index) => (
                    <div key={index} className="flex flex-col px-4 self-stretch">
                      <div
                        className={`flex   flex-row justify-between items-center  self-stretch flex-1 py-4 ${myhyundai.length > 1 && 'border-b border-border'
                          }`}
                      >
                        <div className="mr-2 grow-0">
                          <Icon icon="email" size={20} />
                        </div>
                        <div className="flex flex-col items-start w-full">
                          <p className="text-sm text-black grow flex-1 ">{res?.Email}</p>
                          <p className="bg-secondary py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelVerified}</p>
                        </div>
                        {selectAdditional?.length > 1 && (
                          <button onClick={() => onShowModalDelete(res.Key, 'hyundai')}>
                            <Icon icon="trash-can" color="red" size={20} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {ctb && ctb?.length > 0 && (
              <div className="flex flex-col items-start py-4 my-4 border-2 border-border bg-white w-full md:w-[480px] lg:h-auto">
                <div className="flex flex-col items-start px-4">
                  <p className="text-xs text-neutral">{LocalizedString.idMigration.labelPlatform}</p>
                  <h1 className="text-lg text-primary ">{LocalizedString.idMigration.labelClickToBuy}</h1>
                </div>
                <div className="flex flex-col items-start self-stretch ">
                  {ctb.map((res, index) => (
                    <div key={index} className="flex flex-col px-4 self-stretch">
                      <div
                        className={`flex   flex-row justify-between items-center  self-stretch flex-1 py-4 ${ctb.length > 1 && 'border-b border-border'
                          }`}
                      >
                        <div className="mr-2 grow-0">
                          <Icon icon="email" size={20} />
                        </div>
                        <div className="flex flex-col items-start w-full">
                          <p className="text-sm text-black grow flex-1 ">{res?.Email}</p>
                          <p className="bg-secondary py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelVerified}</p>
                        </div>
                        {selectAdditional?.length > 1 && (
                          <button onClick={() => onShowModalDelete(res.Key, 'ctb')}>
                            <Icon icon="trash-can" color="red" size={20} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {bluelink && bluelink?.length > 0 && (
              <div className="flex flex-col items-start py-4 my-4 border-2 border-border bg-white w-full md:w-[480px] lg:h-auto">
                <div className="flex flex-col items-start px-4">
                  <p className="text-xs text-neutral">{LocalizedString.idMigration.labelPlatform}</p>
                  <h1 className="text-lg text-primary ">{LocalizedString.idMigration.labelBluelink}</h1>
                </div>
                <div className="flex flex-col items-start self-stretch ">
                  {bluelink.map((res, index) => (
                    <div key={index} className="flex flex-col px-4 self-stretch">
                      <div
                        className={`flex   flex-row justify-between items-center  self-stretch flex-1 py-4 ${bluelink.length > 1 && 'border-b border-border'
                          }`}
                      >
                        <div className="mr-2 grow-0">
                          <Icon icon="email" size={20} />
                        </div>
                        <div className="flex flex-col items-start w-full">
                          <p className="text-sm text-black grow flex-1 ">{res?.Email}</p>
                          <p className="bg-secondary py-1 px-2 text-white text-xs mr-2">{LocalizedString.idMigration.labelVerified}</p>
                        </div>
                        {selectAdditional?.length > 1 && (
                          <button onClick={() => onShowModalDelete(res.Key, 'bluelink')}>
                            <Icon icon="trash-can" color="red" size={20} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <button
              // onClick={onSaveHandler}
              onClick={onCancelAddtional}
              className="flex flex-col items-center  py-[14px] px-6 text-primary  text-base w-full md:w-[480px] my-4"
            >
              {LocalizedString.idMigration.labelCancelAdditionalAccount}
            </button>
            <button
              onClick={onConfirmSaveHandler}
              className=" flex flex-col items-center  py-[14px] px-6 bg-primary text-white text-base w-full md:w-[480px]"
            >
              {LocalizedString.idMigration.labelAddSearchedAccount}
            </button>
          </div>
        </div>
        <ModalInfo
          title={modalInfo.title}
          desc={modalInfo.desc}
          btnTextConfirm={modalInfo.confirm}
          btnTextCancel={modalInfo.cancel}
          show={modalDelete}
          onCloseModal={() => setModalDelete(false)}
          onCancel={() => setModalDelete(false)}
          onConfirm={onActionModal}
          isReverseBtn={true}
        />
        {loading && <Loading />}
      </div>
    </>
  );
};

export default AdditionalAccount;
