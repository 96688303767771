import FindId from './pages/findId';
import Main from './pages/main';

import { Route, Routes } from 'react-router-dom';
import Success from './pages/succes';
import HyundaiOneId from './pages/hyundaiOneId';

function App() {
  return (
    //  <React.StrictMode>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/findid" element={<FindId />} />
      <Route path="/find-ctb-account" element={<FindId source="ctb" />} />
      <Route path="/success" element={<Success />} />
      <Route path="/hyundai-one-id" element={<HyundaiOneId />} />
    </Routes>
    // </React.StrictMode>
  );
}

export default App;
