
import LocalizedStrings from 'localized-strings';
// import { statePhoneWithFormat } from '';

export const LocalizedString = new LocalizedStrings({
  'en-US': {
    idMigration: {
      title: 'ID Migration Process',
      labelSearchYourAccount: 'Search Your Account',
      labelSearchResults: 'Search Results',
      labelChooseIDToUse: 'Choose ID to Use',
      errMessageNext: 'Please select an account',
      loadingDesc: 'Please wait, waiting for the process to be finished...',
      titleCancelIdMigration: 'Cancel Integration Process',
      descCancelIdMigration: 'Are you sure to cancel all process Hyundai One ID?',
      labelYesCancelIdMigration: 'Yes',
      labelNoCancelIdMigration: 'No, Continue',
      labelHi: 'Hi,',
      labeldescIdMigration: 'We will connect your all hyundai accounts to become one, please input your valid phone number to start migration.',
      labelPhoneNumber: 'Phone Number',
      labelBtnPleaseWait: 'Please wait',
      labelBtnContinue: 'Continue',
      titleOtpCode: 'OTP Code Verification',
      // descOtpCode: `A code is sent to your phone number ${statePhoneWithFormat}. Please check your inbox and copy the OTP Code here.`,
      descOtpCodeA: `A code is sent to your phone number`,
      descOtpCodeB: `. Please check your inbox and copy the OTP Code here.`,
      btnVerifyOtp: 'Verify OTP',
      labelOtpExpiredIn: 'OTP expired in',
      labelMin: 'min',
      labelSecond: 'second',
      textDidntReceiveCode: `Didn't recieve code?`,
      textResendAnOtpCodeIn: 'Resend an OTP Code in',
      btnResendOtp: 'Resend OTP',
      labelPleaseWait: 'Please Wait',
      labelVerifyOtp: 'Verify OTP',
      labelWelcomeTo: 'Welcome to',
      labelHyundaiOneId: 'Hyundai One ID',
      labelSignIn: 'Sign In',
      descSignIn: 'Sign in using your email, you can also use "Bluelink" or "Click To Buy" account to continue.',
      placeholderEmailAddress: 'Email Address',
      labelLoadingPleaseWait: 'Please wait...',
      btnFindID: 'Find ID',
      labelNotMember: 'Not a member?',
      btnRegister: 'Register',
      labelForgotYourId: 'Forgot your ID?',
      descForgotYourId: 'Please filled the form below to get your ID.',
      labelPhoneNumberMandatory: 'Phone Number *',
      labelVerificationCodeMust: 'Verification code must be filled in 5 minutes.',
      labelNotYetReceiveVerificationCode: 'If your not yet receive verification code or get limit time, please click “Resend Code”.',
      labelBtnSendVerificationCode: 'Send Verification Code',
      labelResendCodeIn: `Resend Code In`,
      placeholderVerificationCode: 'Verification Code',
      labelBtnSubmit: 'Submit',
      labelSuccess: 'Success',
      descPleaseFindYourID: 'Please find your ID to access your account.',
      btnGotoLogin: 'Go To Login',
      errUserWasNotFound: 'The user was not found',
      infoFoundSomeEmails: 'We found some emails that use email:',
      infoAndMobilePhone: 'and mobile phone:',
      infoPleaseVerifyAdditionalAccount: 'Please verify additional account to integrate, you can choose one email ID per platform.',
      infoLetsHighlightOneId: `Let's highlight "one ID" per platform. All of the benefits from each myHyundai ID you verified will be combined with your Hyundai One ID.`,
      labelPlatform: 'Platform',
      infoAccountPlatformNotFound: 'Account for this platform not found!',
      infoDoYouUserEmailForOtherPlatforms: 'Do you user email for other platforms ?',
      labelSearchAdditionalAccount: 'Search Additional Account',
      labelVerified: 'Verified',
      titleDeleteEmailID: 'Delete Email ID',
      descDeleteEmailID: 'Are you sure to delete additional account ?',
      btnNo: 'No',
      btnYesDelete: 'Yes, Delete',
      labelAddAdditionalAccount: 'Add Additional Account',
      titleAddAccountConfirmation: 'Add Account Confirmation',
      descAddAccountConfirmation: 'Are you sure to continue add account ?',
      btnYesAddAccount: 'Yes, Add Account',
      errFailedToAddYourAccount: 'Failed to add your account',
      titleCancelAddingAccount: 'Cancel Adding Account',
      descCancelAddingAccount: 'Are you sure to cancel searching for additional accounts?',
      btnYesCancel: 'Yes, Cancel',
      labelVerificationSucceded: 'Verification Succeded',
      labelPLeaseChooseAccountsToIntegrate: 'Please choose accounts to integrate.',
      labelMyHyundai: 'MyHyundai',
      labelClickToBuy: 'CLick TO Buy',
      labelBluelink: 'Bluelink',
      labelCancelAdditionalAccount: 'Cancel Additional Account',
      labelAddSearchedAccount: 'Add the Searched Account',
      labelNotVerified: 'Not Verified',
      descEmailA: `A code is sent to your email`,
      descEmailB: `. Please check your inbox and copy the OTP Code here.`,
      btnOk: 'ok',
      labelInsertYourEmailOrPhone: 'Insert your email or phone number to search',
      placeholderEmailOrPhoneNumber: 'Email or Phone Number',
      labelSearch: 'Search',
      labelPleaseChooseRepresentativeEmailForLogin: `Please choose a representative e-mail to use for log-in. This e-mail address will be your ID for all Hyundai's digital platforms - MyHyundai / Bluelink / Click to Buy. Also, all service data under the following e-mail addresses will be automatically connected to your ID.`,
      labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID: 'You can also use an ID from one Platform to create Hyundai One ID. This process will only be conducted once and you will not be required to redo this process again.',
      labelCongratulations: 'Congratulations',
      labelYouHaveFinishedIDMigrationProcess: 'You have finished the ID Migration process',
      labelYears: 'years',
      labelYear: 'year',
      labelMonth: 'month',
      labelDay: 'day',
      labelMakeAProfile: 'Make a Profile',
      labelYouAreCurrentlyMigratingBluelinkID: 'You are currently migrating a Bluelink ID, you will be able to change your profile information later after the Migration process.',
      labelFullName: 'Full Name',
      placeholderYourFullName: 'Your Full Name',
      errYourNameIsRequired: 'Your Name is Required',
      placeholderDOB: 'DOB',
      labelDateOfBirth: 'Date of Birth',
      placeholderSelectADate: 'Select a date',
      placeholderPhoneNumber: 'Phone Number',
      errPhoneNumberRequired: 'Your Phone Number is Required',
      labelEmail: 'Email',
      placeholderEmail: 'Email',
      labelCreateNewPassword: 'Create New Password *',
      placeholderPassword: 'Password',
      labelText: 'text',
      infoUppercaseLowercaseNumberSpecialCharacter: 'Min 1 uppercase, 1 lowercase, 1 number, special character',
      labelConfirmPassword: 'Confirm Password *',
      placeholderConfirmPassword: 'Confirm Password',
      labelPasswordDoesntMatch: `Password doesn't match`,
      labelPIN: 'PIN *',
      placeholderPIN: 'PIN - 4 digits number',
      labelInput4DigitsPIN: 'Input 4 digits PIN',
      labelConfirmPIN: 'Confirm PIN *',
      placeholderConfirmPIN: 'Confirm PIN',
      errConfirmPIN: `PIN doesn't match`,
      labelIAgreeTo: 'I agree to',
      labelPrivacyPolicy: 'privacy policy',
      labelMyHundaiIndonesia: 'myHyundai Indonesia',
      labelWeFoundSomeAccountsThatUsingEmail: 'We found some accounts that using email',
      labelPleaseVerifyAdditionalAccountToIntegrate: 'Please verify additional account to integrate',
      titlePrivacyPolicy: "Privacy Policy",
      descPin: 'This PIN is used for transactional activities',
    },
  },
  ID: {
    idMigration: {
      title: 'ID Migration Process',
      labelSearchYourAccount: 'Search Your Account',
      labelSearchResults: 'Search Results',
      labelChooseIDToUse: 'Choose ID to Use',
      errMessageNext: 'Please select an account',
      loadingDesc: 'Please wait, waiting for the process to be finished...',
      titleCancelIdMigration: 'Cancel Integration Process',
      descCancelIdMigration: 'Are you sure to cancel all process Hyundai One ID?',
      labelYesCancelIdMigration: 'Yes',
      labelNoCancelIdMigration: 'No, Continue',
      labelHi: 'Hi,',
      labeldescIdMigration: 'We will connect your all hyundai accounts to become one, please input your valid phone number to start migration.',
      labelPhoneNumber: 'Phone Number',
      labelBtnPleaseWait: 'Please wait',
      labelBtnContinue: 'Continue',
      titleOtpCode: 'OTP Code Verification',
      // descOtpCode: `A code is sent to your phone number ${statePhoneWithFormat}. Please check your inbox and copy the OTP Code here.`,
      descOtpCodeA: `A code is sent to your phone number`,
      descOtpCodeB: `. Please check your inbox and copy the OTP Code here.`,
      btnVerifyOtp: 'Verify OTP',
      labelOtpExpiredIn: 'OTP expired in',
      labelMin: 'min',
      labelSecond: 'second',
      textDidntReceiveCode: `Didn't recieve code?`,
      textResendAnOtpCodeIn: 'Resend an OTP Code in',
      btnResendOtp: 'Resend OTP',
      labelPleaseWait: 'Please Wait',
      labelVerifyOtp: 'Verify OTP',
      labelWelcomeTo: 'Welcome to',
      labelHyundaiOneId: 'Hyundai One ID',
      labelSignIn: 'Sign In',
      descSignIn: 'Sign in using your email, you can also use "Bluelink" or "Click To Buy" account to continue.',
      placeholderEmailAddress: 'Email Address',
      labelLoadingPleaseWait: 'Please wait...',
      btnFindID: 'Find ID',
      labelNotMember: 'Not a member?',
      btnRegister: 'Register',
      labelForgotYourId: 'Forgot your ID?',
      descForgotYourId: 'Please filled the form below to get your ID.',
      labelPhoneNumberMandatory: 'Phone Number *',
      labelVerificationCodeMust: 'Verification code must be filled in 5 minutes.',
      labelNotYetReceiveVerificationCode: 'If your not yet receive verification code or get limit time, please click “Resend Code”.',
      labelBtnSendVerificationCode: 'Send Verification Code',
      labelResendCodeIn: `Resend Code In`,
      placeholderVerificationCode: 'Verification Code',
      labelBtnSubmit: 'Submit',
      labelSuccess: 'Success',
      descPleaseFindYourID: 'Please find your ID to access your account.',
      btnGotoLogin: 'Go To Login',
      errUserWasNotFound: 'The user was not found',
      infoFoundSomeEmails: 'We found some emails that use email:',
      infoAndMobilePhone: 'and mobile phone:',
      infoPleaseVerifyAdditionalAccount: 'Please verify additional account to integrate, you can choose one email ID per platform.',
      infoLetsHighlightOneId: `Let's highlight "one ID" per platform. All of the benefits from each myHyundai ID you verified will be combined with your Hyundai One ID.`,
      labelPlatform: 'Platform',
      infoAccountPlatformNotFound: 'Account for this platform not found!',
      infoDoYouUserEmailForOtherPlatforms: 'Do you user email for other platforms ?',
      labelSearchAdditionalAccount: 'Search Additional Account',
      labelVerified: 'Verified',
      titleDeleteEmailID: 'Delete Email ID',
      descDeleteEmailID: 'Are you sure to delete additional account ?',
      btnNo: 'No',
      btnYesDelete: 'Yes, Delete',
      labelAddAdditionalAccount: 'Add Additional Account',
      titleAddAccountConfirmation: 'Add Account Confirmation',
      descAddAccountConfirmation: 'Are you sure to continue add account ?',
      btnYesAddAccount: 'Yes, Add Account',
      errFailedToAddYourAccount: 'Failed to add your account',
      titleCancelAddingAccount: 'Cancel Adding Account',
      descCancelAddingAccount: 'Are you sure to cancel searching for additional accounts?',
      btnYesCancel: 'Yes, Cancel',
      labelVerificationSucceded: 'Verification Succeded',
      labelPLeaseChooseAccountsToIntegrate: 'Please choose accounts to integrate.',
      labelMyHyundai: 'MyHyundai',
      labelClickToBuy: 'CLick TO Buy',
      labelBluelink: 'Bluelink',
      labelCancelAdditionalAccount: 'Cancel Additional Account',
      labelAddSearchedAccount: 'Add the Searched Account',
      labelNotVerified: 'Not Verified',
      descEmailA: `A code is sent to your email`,
      descEmailB: `. Please check your inbox and copy the OTP Code here.`,
      btnOk: 'ok',
      labelInsertYourEmailOrPhone: 'Insert your email or phone number to search',
      placeholderEmailOrPhoneNumber: 'Email or Phone Number',
      labelSearch: 'Search',
      labelPleaseChooseRepresentativeEmailForLogin: `Please choose a representative e-mail to use for log-in. This e-mail address will be your ID for all Hyundai's digital platforms - MyHyundai / Bluelink / Click to Buy. Also, all service data under the following e-mail addresses will be automatically connected to your ID.`,
      labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID: 'You can also use an ID from one Platform to create Hyundai One ID. This process will only be conducted once and you will not be required to redo this process again.',
      labelCongratulations: 'Congratulations',
      labelYouHaveFinishedIDMigrationProcess: 'You have finished the ID Migration process',
      labelYears: 'years',
      labelYear: 'year',
      labelMonth: 'month',
      labelDay: 'day',
      labelMakeAProfile: 'Make a Profile',
      labelYouAreCurrentlyMigratingBluelinkID: 'You are currently migrating a Bluelink ID, you will be able to change your profile information later after the Migration process.',
      labelFullName: 'Full Name',
      placeholderYourFullName: 'Your Full Name',
      errYourNameIsRequired: 'Your Name is Required',
      placeholderDOB: 'DOB',
      labelDateOfBirth: 'Date of Birth',
      placeholderSelectADate: 'Select a date',
      placeholderPhoneNumber: 'Phone Number',
      errPhoneNumberRequired: 'Your Phone Number is Required',
      labelEmail: 'Email',
      placeholderEmail: 'Email',
      labelCreateNewPassword: 'Create New Password *',
      placeholderPassword: 'Password',
      labelText: 'text',
      infoUppercaseLowercaseNumberSpecialCharacter: 'Min 1 uppercase, 1 lowercase, 1 number, special character',
      labelConfirmPassword: 'Confirm Password *',
      placeholderConfirmPassword: 'Confirm Password',
      labelPasswordDoesntMatch: `Password doesn't match`,
      labelPIN: 'PIN *',
      placeholderPIN: 'PIN - 4 digits number',
      labelInput4DigitsPIN: 'Input 4 digits PIN',
      labelConfirmPIN: 'Confirm PIN *',
      placeholderConfirmPIN: 'Confirm PIN',
      errConfirmPIN: `PIN doesn't match`,
      labelIAgreeTo: 'I agree to',
      labelPrivacyPolicy: 'privacy policy',
      labelMyHundaiIndonesia: 'myHyundai Indonesia',
      labelWeFoundSomeAccountsThatUsingEmail: 'We found some accounts that using email',
      labelPleaseVerifyAdditionalAccountToIntegrate: 'Please verify additional account to integrate',
      titlePrivacyPolicy: "Kebijakan pribadi",
      descPin: 'PIN ini digunakan untuk aktivitas transaksional',
    },
  }
})