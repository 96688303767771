import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Icon from '../../components/icon';
import Loading from '../../components/loading';
import { alertMessage } from '../../helpers/helper';
import { initialStateProps } from '../../helpers/types';
import { submitMigration } from '../../ssoSlicer/ssoSlice';
import { LocalizedString } from '../../shared/localization';

interface MakeProfileProps {
  account: any;
  onBack: any;
  onNext: any;
  onShowPrivacyPolicy: () => void
}

const MakeProfile: FC<MakeProfileProps> = ({ account, onBack, onNext, onShowPrivacyPolicy }) => {
  const dispatch = useDispatch();
  const { contryCode } = useSelector((state: initialStateProps) => state);

  const [password, setPassword] = useState('');
  const [checkbox, setCheckBox] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [name, setName] = useState(account?.fullName);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);
  const [showPin, setShowPin] = useState<boolean>(false);
  const [showConfirmPin, setShowConfirmPin] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [codePhone, setCodePhone] = useState('');

  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');

  const [dob, setDob] = React.useState<DayValue>(null);
  const minimumDate = {
    year: moment().add('-80', 'years').get('year'),
    month: moment().add('-80', 'years').get('month'),
    day: moment().add('-80', 'years').get('day'),
  };
  const maximumDate = {
    year: moment().add('-17', 'years').get('year'),
    month: moment().add('-17', 'years').get('month'),
    day: moment().add('-17', 'years').get('day'),
  };
  const zeroPad = (num: any, places: number) => String(num).padStart(places, '0');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      PASSWORD: password,
      CONFIRMPASSWORD: confirmPassword,
      PIN: pin,
      NAME: name,
      PHONE: `${codePhone}${currentPhoneNumber}`,
      CONFIRMPIN: confirmPin,
      IdDetail: account.id,
      Key: account.key,
      DOB: dob != null ? `${dob?.year}-${zeroPad(dob?.month, 2)}-${zeroPad(dob?.day, 2)}` : '',
      Email: account?.email,
    };
    setLoading(true);
    dispatch(submitMigration(payload))
      .unwrap()
      .then((res: any) => {
        setLoading(false);
        if (res?.response?.status === 400 || res?.response?.status === 500) {
          const message = res?.response?.data.Message?.split('|')[0];
          alertMessage.error(message, 3000);
          return;
        }
        onNext();
      })
      .catch((err: any) => {
        setLoading(false);
        if (err?.response.Status === 400 || err?.response.Status === 500) {
          const message = err?.response.Message?.split('|')[0];
          alertMessage.error(message, 3000);
          return;
        }
      });
  };
  // const onErrors = (errors: any) => console.error(errors);]

  const dobBlueLink = moment(account?.dateOfBirth).format('YYYY-MM-DD') || '';

  useEffect(() => {
    if (account?.phoneNumber) {
      var result = contryCode.findIndex((code) => {
        return account?.phoneNumber.startsWith(code.value);
      }, account?.phoneNumber);
      if (result != -1) {
        const { value } = contryCode[result];
        const phone = account?.phoneNumber.replace(value, '');
        setCodePhone(value);
        setCurrentPhoneNumber(phone);
      } else {
        setCodePhone(localStorage.getItem('codePhone') || '+62');
        setCurrentPhoneNumber(localStorage.getItem('currentPhoneNumber') || '');
        // console.log('sini bang');
      }
    } else {
      setCodePhone(localStorage.getItem('codePhone') || '+62');
      setCurrentPhoneNumber(localStorage.getItem('currentPhoneNumber') || '');
    }
  }, []);

  return (
    <div className="fixed w-full h-full z-10 top-0 left-0 bg-background overflow-auto flex flex-col">
      <header className="p-4 flex items-center bg-white sticky top-0 z-50">
        <button style={{ paddingRight: 16, lineHeight: 0 }} onClick={onBack}>
          <Icon icon="arrow-left" size={20} />
        </button>
        <strong className="font-1">{LocalizedString.idMigration.labelMakeAProfile}</strong>
        <div>&nbsp;</div>
      </header>
      <div className="w-full md:w-[480px] mx-auto flex-1">
        <div className="bg-cyan-50 border-cyan-200 border rounded-b text-cyan-900 px-3 py-3 mt-4" role="alert">
          <div className="flex items-center">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 bg-cyan-500 text-white rounded-full mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="text-xs">{LocalizedString.idMigration.labelYouAreCurrentlyMigratingBluelinkID}</p>
            </div>
          </div>
        </div>
        <form className="md:px-0 p-6" onSubmit={handleSubmit(onSubmit)}>
          <label className="flex flex-col w-full mb-4">
            <p className="mb-1">
              {LocalizedString.idMigration.labelFullName} {account.application !== 'bluelink' && '*'}
            </p>
            <input
              type="text"
              placeholder={LocalizedString.idMigration.placeholderYourFullName}
              value={name}
              disabled={account.application === 'bluelink'}
              readOnly={account.application === 'bluelink'}
              className={`appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200 ${
                account.application === 'bluelink' ? 'bg-gray-200' : 'bg-white'
              }`}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <p className="text-sm text-red-700 mt-1">
              {errors?.NAME ? LocalizedString.idMigration.errYourNameIsRequired : ''}
            </p>
          </label>

          {account.application !== 'bluelink' && (
            <div className="flex flex-col w-full mb-4">
              <p className="mb-1">
                {LocalizedString.idMigration.labelDateOfBirth} {account.application !== 'bluelink' && '*'}
              </p>
              <DatePicker
                value={dob}
                onChange={setDob}
                minimumDate={minimumDate}
                maximumDate={maximumDate}
                colorPrimary="rgb(0 44 95 / var(--tw-bg-opacity))"
                inputPlaceholder={LocalizedString.idMigration.placeholderSelectADate}
                inputClassName={`appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none text-left z-1`}
              />
            </div>
          )}

          {account.application === 'bluelink' && (
            <label className="flex flex-col w-full mb-4">
              <p className="mb-1">{LocalizedString.idMigration.labelDateOfBirth}</p>
              <input
                type="text"
                placeholder={LocalizedString.idMigration.placeholderDOB}
                // value={(dob != null ? `${dob?.year}-${zeroPad(dob?.month, 2)}-${zeroPad(dob?.day, 2)}`: '')}
                value={dobBlueLink}
                disabled={account.application === 'bluelink'}
                readOnly={account.application === 'bluelink'}
                className={`bg-gray-200 border-none appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200`}
              />
            </label>
          )}
          <label className="flex flex-col w-full mb-4">
            <p className="mb-1">
              {LocalizedString.idMigration.labelPhoneNumber} {account.application !== 'bluelink' && '*'}
            </p>
            <div className="flex flex-row items-center self-stretch pt-1">
              <Select
                defaultValue={{ label: '+62', value: '+62' }}
                options={contryCode}
                className="focus:ring-blue-500 focus:border-primary rounded-none"
                onChange={(e: any) => {
                  setCodePhone(e.value);
                }}
                isDisabled={true}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#ddd',
                    backgroundColor: 'rgb(229 231 235 / var(--tw-bg-opacity))',
                    minHeight: '46px',
                    borderRadius: '0',
                    marginRight: '-1px',
                    width: '100px',
                  }),
                }}
              />
              <input
                className={`bg-gray-200 appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200`}
                type="tel"
                disabled
                readOnly
                value={currentPhoneNumber || ''}
                placeholder={LocalizedString.idMigration.placeholderPhoneNumber}
              />
            </div>
            <p className="text-sm text-red-700 mt-1">
              {errors?.PHONENUMBER ? LocalizedString.idMigration.errPhoneNumberRequired : ''}
            </p>
          </label>
          <label className="flex flex-col w-full mb-4">
            <p className="mb-1">{LocalizedString.idMigration.labelEmail}</p>
            <input
              className="bg-gray-200 appearance-none w-full py-3 px-4 leading-tight focus:outline-none border-none focus:border-gray-200"
              type="email"
              value={account?.email}
              placeholder={LocalizedString.idMigration.placeholderEmail}
              disabled
              readOnly
              {...register('Email', { required: false })}
            />
          </label>
          {account.application !== 'bluelink' && (
            <>
              <label className="flex flex-col w-full mb-4">
                <p className="mb-1">{LocalizedString.idMigration.labelCreateNewPassword}</p>
                <div className="relative">
                  <input
                    className={`appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200 ${
                      account.application === 'bluelink' ? 'bg-gray-200' : 'bg-white'
                    }`}
                    type={showPass ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder={LocalizedString.idMigration.placeholderPassword}
                    readOnly={account.application === 'bluelink'}
                    disabled={account.application === 'bluelink'}
                  />
                  <div
                    className="absolute box-border -translate-y-1/2 top-1/2 right-2 cursor-pointer"
                    onClick={() => setShowPass(!showPass)}
                  >
                    <Icon icon={showPass ? 'view' : 'view-off'} size={20} />
                  </div>
                </div>
                <PasswordStrengthBar
                  className="passwordStrengthBar"
                  minLength={8}
                  shortScoreWord={''}
                  scoreWords={[]}
                  barColors={['#ddd', '#F8772E', '#f6b44d', '#2b90ef', '#25c281']}
                  password={password}
                  onChangeScore={(score, feedback) => {
                    setPasswordStrength(score);
                  }}
                />
                <p className="text-sm">{LocalizedString.idMigration.infoUppercaseLowercaseNumberSpecialCharacter}</p>
              </label>
              <label className="flex flex-col w-full mb-4">
                <p className="mb-1">{LocalizedString.idMigration.labelConfirmPassword}</p>
                <div className="relative">
                  <input
                    className={`appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200 ${
                      account.application === 'bluelink' ? 'bg-gray-200' : 'bg-white'
                    }`}
                    type={showConfirmPass ? 'text' : 'password'}
                    placeholder={LocalizedString.idMigration.placeholderConfirmPassword}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    readOnly={account.application === 'bluelink'}
                    disabled={account.application === 'bluelink'}
                  />
                  <div
                    className="absolute box-border -translate-y-1/2 top-1/2 right-2 cursor-pointer"
                    onClick={() => setShowConfirmPass(!showConfirmPass)}
                  >
                    <Icon icon={showConfirmPass ? 'view' : 'view-off'} size={20} />
                  </div>
                </div>
                {confirmPassword && password && confirmPassword !== password && (
                  <p className="text-sm text-red-700 mt-1">{LocalizedString.idMigration.labelPasswordDoesntMatch}</p>
                )}
              </label>
              <label className="flex flex-col w-full mb-4">
                <p className="mb-1">{LocalizedString.idMigration.labelPIN}</p>
                <div className="relative">
                  <input
                    className={`appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200 ${
                      account.application === 'bluelink' ? 'bg-gray-200' : 'bg-white'
                    }`}
                    type={showPin ? 'text' : 'password'}
                    inputMode="tel"
                    maxLength={4}
                    placeholder={LocalizedString.idMigration.placeholderPIN}
                    readOnly={account.application === 'bluelink'}
                    disabled={account.application === 'bluelink'}
                    value={pin}
                    onChange={(e) => {
                      setPin(e.target.value);
                    }}
                  />
                  <div
                    className="absolute box-border -translate-y-1/2 top-1/2 right-2 cursor-pointer"
                    onClick={() => setShowPin(!showPin)}
                  >
                    <Icon icon={showPin ? 'view' : 'view-off'} size={20} />
                  </div>
                  <div className="flex flex-row items-center mt-1">
                    <Icon icon="information-filled" size={14} color='#00AAD2' />
                    <p className="text-xs ml-[4px]">{LocalizedString.idMigration.descPin}</p>
                  </div>
                </div>

                {pin && pin.length !== 4 && (
                  <p className="text-sm text-red-700 mt-1">{LocalizedString.idMigration.labelInput4DigitsPIN}</p>
                )}
              </label>
              <label className="flex flex-col w-full mb-4">
                <p className="mb-1">{LocalizedString.idMigration.labelConfirmPIN}</p>
                <div className="relative">
                  <input
                    className={`appearance-none w-full py-3 px-4 leading-tight focus:outline-none outline-none border-none focus:border-gray-200 ${
                      account.application === 'bluelink' ? 'bg-gray-200' : 'bg-white'
                    }`}
                    type={showConfirmPin ? 'text' : 'password'}
                    inputMode="tel"
                    maxLength={4}
                    placeholder={LocalizedString.idMigration.placeholderConfirmPIN}
                    readOnly={account.application === 'bluelink'}
                    disabled={account.application === 'bluelink'}
                    value={confirmPin}
                    onChange={(e) => {
                      setConfirmPin(e.target.value);
                    }}
                  />{' '}
                  <div
                    className="absolute box-border -translate-y-1/2 top-1/2 right-2 cursor-pointer"
                    onClick={() => setShowConfirmPin(!showConfirmPin)}
                  >
                    <Icon icon={showConfirmPin ? 'view' : 'view-off'} size={20} />
                  </div>
                </div>
                {confirmPin && pin && confirmPin !== pin && (
                  <p className="text-sm text-red-700 mt-1">{LocalizedString.idMigration.errConfirmPIN}</p>
                )}
              </label>
            </>
          )}
        </form>
      </div>
      <footer className="p-4 text-center flex justify-center flex-row items-center bg-white sticky bottom-0 left-0 z-10">
        <div className="w-full md:w-[500px]">
          <div className="flex flex-row items-center py-4">
            <input
              type="checkbox"
              name="checkbox"
              id="checkbox"
              checked={checkbox}
              onChange={() => setCheckBox(!checkbox)}
              className="border-secondary text-secondary focus:outline-none w-6 h-6 mr-2"
            />
            <p className="text-sm text-left">
              {LocalizedString.idMigration.labelIAgreeTo}&nbsp;
              <span onClick={onShowPrivacyPolicy}>
                <a href="#" rel="noopener noreferrer" className="cursor-pointer text-secondary">
                  {LocalizedString.idMigration.labelPrivacyPolicy}
                </a>
              </span>
              &nbsp; {LocalizedString.idMigration.labelMyHundaiIndonesia}
            </p>
          </div>
          <button
            onClick={handleSubmit(onSubmit)}
            className="bg-primary hover:bg-secondary text-white font-bold py-3 px-4 w-full disabled:bg-gray-500"
            disabled={
              !checkbox ||
              (account.application !== 'bluelink' &&
                ((pin && pin.length !== 4) ||
                  !name ||
                  !password ||
                  !pin ||
                  password !== confirmPassword ||
                  pin !== confirmPin ||
                  !dob ||
                  !currentPhoneNumber ||
                  !codePhone))
            }
          >
            {LocalizedString.idMigration.labelBtnSubmit}
          </button>
        </div>
      </footer>
      {loading && <Loading description={LocalizedString.idMigration.loadingDesc} />}
    </div>
  );
};

export default MakeProfile;
