import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { alertMessage } from '../../helpers/helper';
import { useDispatch } from 'react-redux';
import { doGetUrlLogin } from '../../ssoSlicer/ssoSlice';
import { ToastContainer } from 'react-toastify';
import { LocalizedString } from '../../shared/localization';

const HyundaiOneId = () => {
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId') || '';

  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmail = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  const handleRegister = () => {
    const url =
      // `  ${process.env.REACT_APP_API_URL}/bluectb/api/v1/migration/register/${clientId}`; // DEV
      `  ${process.env.REACT_APP_API_URL}/api/v1/migration/register/${clientId}`; // UAT
    window.location.href = url;
  };

  const handleFindID = () => {
    navigate(`/findid?clientId=${clientId}`);
  };

  const handleLogin = async () => {
    setLoading(true);
    // const url = `${process.env.REACT_APP_API_URL}/bluectb/api/v1/migration/SignIn/myHyundai/${email}/${clientId}`; // DEV
    const url = `${process.env.REACT_APP_API_URL}/api/v1/migration/SignIn/myHyundai/${email}/${clientId}`; // UAT
    dispatch(doGetUrlLogin(url))
      .unwrap()
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 500) {
          const message = res?.message;
          alertMessage.error(message, 3000);
          setTimeout(() => (window.location.href = res?.url), 1000);
          return;
        }
        window.location.href = res?.url;
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen bg-background p-4 ">
        <div className="flex flex-col">
          <h2 className="text-lg text-black hyundaiTextHead">{LocalizedString.idMigration.labelWelcomeTo}</h2>
          <h1 className="text-3xl text-black hyundaiTextHead mb-6">{LocalizedString.idMigration.labelHyundaiOneId}</h1>
          <div className="p-4 bg-white flex flex-col">
            <h2 className="text-lg text-black hyundaiTextHead pb-1">{LocalizedString.idMigration.labelSignIn}</h2>
            <p className="text-sm">
              {LocalizedString.idMigration.descSignIn}
            </p>
            <input
              className={`my-4 bg-gray-100 appearance-none w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline ${error ? 'border-red-500' : ''
                }`}
              id="email"
              type="email"
              placeholder={LocalizedString.idMigration.placeholderEmailAddress}
              value={email}
              onChange={handleEmail}
            />
            <button
              disabled={!email || error || loading}
              onClick={handleLogin}
              className="flex flex-row items-center justify-center py-[14px] px-[24px] bg-primary text-base text-white hyundaiTextHead self-stretch"
            >
              {loading ? LocalizedString.idMigration.labelLoadingPleaseWait : LocalizedString.idMigration.labelBtnContinue}
            </button>
            <button
              onClick={handleFindID}
              className="mt-3 flex flex-row items-center justify-center py-[14px] px-[24px] text-base hyundaiTextHead self-stretch text-primary"
            >
              {LocalizedString.idMigration.btnFindID}
            </button>
          </div>

          <div className="border-separate my-6 text-center flex flex-row justify-evenly items-center">
            <span className="line"></span>
            <span className="px-4 bg-background flex-none">{LocalizedString.idMigration.labelNotMember}</span>
            <span className="line"></span>
          </div>
          <button
            onClick={handleRegister}
            className="flex flex-row items-center justify-center py-[14px] px-[24px] bg-sky-500 text-base text-white hyundaiTextHead self-stretch"
          >
            {LocalizedString.idMigration.btnRegister}
          </button>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default HyundaiOneId;
