import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../components/loading';
import Platform from '../../components/platform';
import { getChosenId } from '../../ssoSlicer/ssoSlice';
import MakeProfile from '../makeProfile';
import { LocalizedString } from '../../shared/localization';

interface ChooseIDProps {
  onSelectionChange: any;
}
const ChooseID: FC<ChooseIDProps> = ({ onSelectionChange }) => {

  const accountList = useSelector(getChosenId);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [hasBluelink, setHasBluelink] = useState(false);

  useEffect(() => {
    if (accountList && accountList.length) {
      setAccounts(accountList);
      const bluelink = accountList.filter((data: any) => data.platform === 'Bluelink');
      setHasBluelink(bluelink.length);
    }
  }, [accountList]);

  const handleOptionChange = (e: any) => {
    const acc = accounts.filter(val => val.id === Number(e));
    if (acc.length) {
      onSelectionChange(acc[0]);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-start md:w-[480px]">
          <p className="mb-6">
            {LocalizedString.idMigration.labelPleaseChooseRepresentativeEmailForLogin}
          </p>
          {accounts.map((val: any, index) => (
            <Platform key={index} item={val} clickHandleOptionChange={handleOptionChange} hasBluelink={hasBluelink} />
          ))}

          <div className="bg-cyan-50 border-cyan-200 border rounded-b text-cyan-900 px-3 py-3 mt-4" role="alert">
            <div className="flex items-center">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 bg-cyan-500 text-white rounded-full mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="text-xs">
                  {LocalizedString.idMigration.labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseID;
