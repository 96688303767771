import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ModalOtp from '../../components/modal/ModalOtp/ModalOtp';
import { alertMessage } from '../../helpers/helper';
import { onGetUserList, onSendOtp, onValidateOtp, getAccount, setAccountList, getCountryCode } from '../../ssoSlicer/ssoSlice';
import Loading from '../../components/loading';
import Select from 'react-select'
import { initialStateProps } from '../../helpers/types';
import { LocalizedString } from '../../shared/localization';

interface IdMigrationProps {
  onNext?: any;
}

const IdMigration: FC<IdMigrationProps> = ({ onNext }) => {

  const dispatch = useDispatch();

  const account = useSelector(getAccount);
  const { contryCode } = useSelector((state: initialStateProps) => state);
  const [otpResponse, setOtpResponse] = useState({
    id: null,
    key: null,
    OTP: null,
    PhoneNumber: null,
    Email: null,
    RequestUsing: "phone",
    Application: "",
    OtpFrom: "CtbMyIDM",
  });

  const [statePhone, setStatePhone] = useState('');
  const [statePhoneWithFormat, setStatePhoneWithFormat] = useState('');
  const [showModalOtp, setShowModalOtp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOtp, setLoadingOtp] = useState<boolean>(false);
  const [codePhone, setCodePhone] = useState<string>("+62");


  useEffect(() => {
    if (!contryCode.length) {
      dispatch(getCountryCode());
    }
  }, [dispatch, contryCode]);

  const HandlePhoneNumber = (e: any) => {
    const { value } = e.target;
    const textboxText = value.replace(/^0+/, '')
    setStatePhone(textboxText);
  };
  const SendOTP = () => {
    setLoadingOtp(true);

    const newPhone = statePhone.replace(/^0+/, '');
    const formatPhone = codePhone + newPhone;
    setStatePhoneWithFormat(formatPhone);

    const payload = {
      "PHONENUMBER": formatPhone,
      "REQUESTUSING": "PHONE"
    };
    dispatch(onSendOtp(payload))
      .unwrap()
      .then((res: any) => {
        if (res && res.key) {
          setOtpResponse(res);
          setShowModalOtp(true);
        } else {
          alertMessage.error(res.message);
        }
        setLoadingOtp(false);
      })
      .catch((err: any) => {
        setLoadingOtp(false);
        console.log(err, "ini rerorr");
      });
  }

  const ValidateOTP = (code: any) => {
    setLoading(true);

    const payload = {
      "IdOtpSSO": otpResponse.id,
      "Key": otpResponse.key,
      "OTP": code,
      "PhoneNumber": statePhoneWithFormat,
      "Email": "",
      "RequestUsing": "phone",    //email atau phone
      "Application": "",
      "OtpFrom": "CtbMyIDM"     //SearchAddAccount atau BluelinkIDM atau CtbMyIDM
    };
    dispatch(onValidateOtp(payload))
      .unwrap()
      .then((res: any) => {
        setLoading(false);
        setShowModalOtp(false);
        localStorage.setItem('codePhone', codePhone);
        localStorage.setItem('currentEmail', account?.email);
        localStorage.setItem('currentPhoneNumber', statePhone);
        GetUserLists(statePhoneWithFormat, account?.email);
      })
      .catch((err: any) => {
        setLoading(false);
        if (err?.response?.status === (500 || 400)) {
          const message = err?.response?.data?.Message.split('|')[0];
          alertMessage.error(message);
          return;
        }
      });
  }

  const GetUserLists = (phone: any, email: string) => {
    setLoading(true);

    const payload = {
      "email": email,
      "phoneNumber": phone,
      "source": "CtbMyIDM",
    }
    dispatch(onGetUserList(payload))
      .unwrap()
      .then((res: any) => {
        if (res) {
          localStorage.setItem('idMigration', res.idMigration);
          const { bluelink, ctb, myhyundai } = res;
          const data = [
            {
              platform: 'Bluelink',
              account: bluelink
            },
            {
              platform: 'myHyundai',
              account: myhyundai
            },
            {
              platform: 'Clicktobuy',
              account: ctb,
            },
          ];

          if (bluelink.length) {
            let idButtonDisabled = '0';
            let inMyHyundai = false;
            let inCtb = false;
            for (let i = 0; i < bluelink.length; i++) {
              const val = bluelink[i];
              if (myhyundai.length) {
                const myhData = myhyundai.filter((v: any) => v.email === val.email);
                if (myhData.length > -1) {
                  inMyHyundai = true
                } else {
                  inMyHyundai = false
                }
              }
              if (ctb.length) {
                const ctbData = ctb.filter((v: any) => v.email === val.email);
                if (ctbData.length > -1) {
                  inCtb = true
                } else {
                  inCtb = false
                }
              }
            }
            idButtonDisabled = (inMyHyundai && inCtb ? '1' : '0')
            localStorage.setItem('disableSearch', idButtonDisabled);
          } else {
            localStorage.setItem('disableSearch', '0');
          }
          dispatch(setAccountList(data));
          onNext();
        }
      })
      .catch((err: any) => console.log(err, "ini rerorr"));
  }

  return (
    <div className="font-sans w-full flex flex-row justify-center items-center flex-1">
      <div className="card w-96 mx-auto bg-white  shadow-xl hover:shadow">
        <div className="w-24 aspect-square mx-auto rounded-full -mt-12 bg-secondary flex justify-center items-center">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.3849 26.0571C36.9346 24.4317 37.2132 22.7328 37.2132 20.987C37.2132 16.7524 35.5642 12.7715 32.57 9.77729C29.5757 6.7831 25.5948 5.13403 21.3603 5.13403H20.6397C16.4052 5.13403 12.4242 6.78303 9.43002 9.77729C6.43583 12.7716 4.78676 16.7524 4.78676 20.987C4.78676 22.7328 5.06542 24.4317 5.61508 26.0571H2.625V36.8659H39.375V26.0571H36.3849ZM6.94853 20.987C6.94853 13.4376 13.0903 7.2958 20.6397 7.2958H21.3603C28.9097 7.2958 35.0515 13.4376 35.0515 20.987C35.0515 22.7451 34.725 24.4473 34.0817 26.0571H30.7681C30.4943 24.6257 29.8615 23.2746 28.9195 22.1442C27.9098 20.9326 26.6092 20.0385 25.1613 19.5277C26.2675 18.4541 26.9569 16.9533 26.9569 15.2937C26.9569 12.0385 24.3087 9.39026 21.0535 9.39026C17.7984 9.39026 15.1501 12.0385 15.1501 15.2937C15.1501 16.9423 15.8302 18.4345 16.9236 19.5065C15.4685 20.0079 14.1584 20.8951 13.1399 22.1012L13.1166 22.1289C12.1603 23.2614 11.5169 24.6175 11.2369 26.0571H7.9183C7.27496 24.4473 6.94853 22.7451 6.94853 20.987ZM21.0535 19.0353C18.9904 19.0353 17.312 17.3568 17.312 15.2937C17.312 13.2305 18.9904 11.552 21.0535 11.552C23.1167 11.552 24.7952 13.2305 24.7952 15.2937C24.7952 17.3568 23.1167 19.0353 21.0535 19.0353ZM22.2819 21.197C24.2102 21.197 26.0243 22.0467 27.2587 23.5281C27.8756 24.2683 28.3149 25.1348 28.5533 26.0571H13.4536C13.6971 25.1317 14.1434 24.2639 14.7686 23.5235L14.792 23.4958C16.0256 22.0349 17.8297 21.1971 19.7417 21.1971H22.2819V21.197ZM37.2132 34.7041H4.78676V28.2188H37.2132V34.7041Z"
              fill="white"
            />
            <path d="M19.9192 30.3806H22.081V32.5424H19.9192V30.3806Z" fill="white" />
            <path d="M14.5146 30.3806H16.6764V32.5424H14.5146V30.3806Z" fill="white" />
            <path d="M9.11035 30.3806H11.2721V32.5424H9.11035V30.3806Z" fill="white" />
            <path d="M25.3235 30.3806H27.4853V32.5424H25.3235V30.3806Z" fill="white" />
            <path d="M30.7279 30.3806H32.8897V32.5424H30.7279V30.3806Z" fill="white" />
          </svg>
        </div>
        <div className="text-center mt-5 text-xl font-medium text-ellipsis overflow-hidden px-5">
          <strong className="text-ellipsis w-full overflow-hidden">{LocalizedString.idMigration.labelHi}{account?.email}</strong>
        </div>
        <div className="px-6 text-center mt-2 font-light text-md">
          <p>
            {LocalizedString.idMigration.labeldescIdMigration}
          </p>
        </div>
        <div className="flex flex-col p-4 mt-4">
          <div className="flex flex-col items-start py-5 w-full">
            <p className="text-base text-black">{LocalizedString.idMigration.labelPhoneNumber}</p>
            <div className="flex flex-row items-center self-stretch pt-1">
              <Select
                defaultValue={{ label: '+62', value: '+62' }}
                options={contryCode}
                className="focus:ring-blue-500 focus:border-primary bg-gray-100 rounded-none"
                onChange={(e: any) => setCodePhone(e.value)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#6b7280',
                    backgroundColor: 'rgb(243 244 246 / var(--tw-bg-opacity))',
                    minHeight: '46px',
                    borderRadius: '0',
                    marginRight: '-1px',
                    width: '100px',
                  }),
                }}

              />
              <input
                className="bg-gray-100 appearance-none w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline"
                id="phone"
                type="text"
                inputMode="numeric"
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
                placeholder={LocalizedString.idMigration.labelPhoneNumber}
                value={statePhone}
                maxLength={14}
                onChange={HandlePhoneNumber}
              />
            </div>
          </div>
          <button disabled={loadingOtp || !statePhone} onClick={SendOTP} className="flex-1 bg-primary hover:bg-secondary text-white py-3 px-4">
            {loadingOtp ? LocalizedString.idMigration.labelBtnPleaseWait : LocalizedString.idMigration.labelBtnContinue}
          </button>
        </div>
      </div>
      {loading && <Loading />}

      {showModalOtp && <ModalOtp
        show={showModalOtp}
        // onCancel={() => setShowModalOtp(false)}
        title={LocalizedString.idMigration.titleOtpCode}
        // desc={`A code is sent to your phone number ${statePhoneWithFormat}. Please check your inbox and copy the OTP Code here.`}
        desc={LocalizedString.idMigration.descOtpCodeA + statePhoneWithFormat + LocalizedString.idMigration.descOtpCodeB}
        onCloseModal={() => setShowModalOtp(false)}
        onConfirm={(code) => ValidateOTP(code)}
        btnTextConfirm={LocalizedString.idMigration.btnVerifyOtp}
        loading={loading}
        onResendOtp={SendOTP}
      />}
    </div>
  );
};

export default IdMigration;
